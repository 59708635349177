import { Route, Routes } from "react-router-dom";

import Audios from "./Admin/Audios";
import Users from "./Admin/Users";

// import Messages from "./Admin/Messages";
import CompleteProfile from "./Auth/CompleteProfile";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";

import AudioEmbed from "./Integration/AudioEmbed";
import RecordEmbed from "./Integration/RecordEmbed";

import AudioDetails from "./Audio";
import CreateRecorder from "./CreateRecorder";
import { Profile } from "./Profile";
import Redirect from "./Redirect";
import { QRCode } from "./Qrcode";

function Router() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/complete-profile" element={<CompleteProfile />} />

            <Route caseSensitive path="/:user/:audioId" element={<AudioDetails />} />
            <Route caseSensitive path="/audio/:audioId" element={<AudioDetails />} />

            <Route caseSensitive path="/:user/c/:collection" element={<Profile />} />
            <Route caseSensitive path="/:user" element={<Profile />} />

            <Route caseSensitive path="/create-recorder" element={<CreateRecorder />} />
            <Route caseSensitive path="/embed/record" element={<RecordEmbed />} />
            <Route caseSensitive path="/embed/:audioId" element={<AudioEmbed />} />
            <Route caseSensitive path="/embed/qrcode/:audioId" element={<QRCode />} />

            <Route caseSensitive path="/admin/users" element={<Users />} />
            <Route caseSensitive path="/admin/audios" element={<Audios />} />

            <Route index element={<Redirect />} />
        </Routes>
    );
}

export default Router;
