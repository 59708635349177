import { createContext, useRef, useState } from "react";

export const StatsData = {};
export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    // const [ audioPlayer, setAudioPlayer ] = useState(false);
    const audioPlayer = useRef(new Audio());
    // audioPlayer.current.crossOrigin = "anonymous";
    const [audioSource, setAudioSource] = useState("");
    const [audioStatus, setAudioStatus] = useState("stop");

    return <GlobalContext.Provider value={{ audioPlayer, audioSource, setAudioSource, audioStatus, setAudioStatus }}>{children}</GlobalContext.Provider>;
};
