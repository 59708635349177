import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { blobToSamples } from "../../utils/audio";
import axios from "axios";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import moment from "moment";

import useAudioRecorder from "../../context/AudioRecorder";
import useFFmpegConverter from "../../context/FFmpegConverter";

import { db } from "../../services/firebase";
import { event_page_recorder, event_vocast_create } from "../../services/stats";

import WaveForm from "../../components/WaveForm";
import { Tip } from "../../components/record/commun";
import { Btn } from "../../components/record/embed";

import defaultBg from "../../Assets/img/default-bg.jpg";
import logoBlack from "../../Assets/img/poweredBy-black.png";
import logoWhite from "../../Assets/img/poweredBy-white.png";
import { ReactComponent as FaUpload } from "../../Assets/pictos/upload-light.svg";
import { ReactComponent as UploadContributor } from "../../Assets/pictos/upload-vocaster.svg";

import { FaCircleCheck, FaMicrophoneSlash, FaPlay, FaRegCircleCheck, FaRotateLeft, FaXmark } from "react-icons/fa6";

const ProgressBar = ({ progress, style, color }) => {
    return (
        <div style={{ height: 12, backgroundColor: "#f3f3f3", borderRadius: 10, width: "100%", overflow: "hidden", ...style }}>
            <div className="progress" style={{ width: `${progress}%`, height: 12, backgroundColor: color, borderRadius: 10 }} />
        </div>
    );
};

export default function RecordEmbed({ params, demo = false, inApp = false }) {
    const { t } = useTranslation();
    const { ...rc } = useAudioRecorder();
    const { ...ffmpeg } = useFFmpegConverter();
    // const { userData: userDataContext } = useUserContext();

    const [activeClass, setActiveClass] = useState(false);
    const [warning, setWarning] = useState("");
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showButtonText, setShowButtonText] = useState(true);

    const [isDownloadingPictures, setIsDownloadingPictures] = useState(false);

    const buttonContainerRef = useRef(null);

    // already published
    const [retrieveAudio, setRetrieveAudio] = useState(false);

    // embed
    const [searchParams] = useSearchParams();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [embedConfig, setEmbedConfig] = useState(JSON.parse(window.atob(params || searchParams.get("p") || "e30=")));

    const [contributorPicture, setContributorPicture] = useState(null);
    const [contributorPictureUrl, setContributorPictureUrl] = useState(userData?.profilePicture || "");
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");

    const handleStopSimple = () => {
        if (rc.status.startsWith("rec-")) {
            rc.handleStop();
            // Go directly to preview mode after stopping
            setTimeout(() => {
                rc.setStatus("rev-pause");
            }, 500);
        } else if (rc.status.startsWith("rev-")) {
            // In simple mode, when in preview and clicking "publish", go directly to save
            handleSave();
        }
    };

    const handleFileContributorPictureChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        if (file.size > 4 * 1024 * 1024) {
            setError(t("Record:fileSizeError"));
            setTimeout(() => setError(false), 2500);
            return;
        }
        setContributorPicture(file);
        const fileUrl = URL.createObjectURL(file);
        setContributorPictureUrl(fileUrl);
    };

    const askForContributorPicture = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".jpg,.jpeg,image/jpeg,.png,image/png";
        input.onchange = (e) => handleFileContributorPictureChange(e);
        input.click();
    };

    const handleFileAudioChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        rc.handleFileAudio(file).then((res) => {
            if (res === "fileSizeError") {
                setError(t("Record:fileSizeError"));
                setTimeout(() => setError(false), 2500);
            }
            if (res === "fileDecodeError") {
                setError(t("Record:fileDecodeError"));
                setTimeout(() => setError(false), 2500);
            }
        });
    };

    const askForAudioFile = () => {
        const audio = document.createElement("audio");
        let acceptTypes = [];
        if (audio.canPlayType("audio/mpeg")) acceptTypes.push(".mp3");
        if (audio.canPlayType('audio/webm; codecs="opus"')) acceptTypes.push(".webm");
        if (audio.canPlayType("audio/aac") || audio.canPlayType('audio/mp4; codecs="mp4a.40.2"')) acceptTypes.push(".aac", ".m4a");

        const input = document.createElement("input");
        input.type = "file";
        input.accept = acceptTypes.join(",");
        input.onchange = (e) => handleFileAudioChange(e);
        input.click();
    };

    const deleteContributorPicture = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setContributorPicture(null);
        setContributorPictureUrl("");
    };

    const handleSave = async () => {
        if (demo) return;
        rc.setStatus("up-progress");

        try {
            const { waveform, duration } = await blobToSamples(rc.audio, 40, 2, 40);
            let needConvert = true;
            let audioAAC = rc.audio;
            try {
                ffmpeg.onProgress((progress) => setProgress(10 + progress * 40)); // Progress from 10% to 50%
                audioAAC = await ffmpeg.convertToAAC(rc.audio);
                needConvert = false;
            } catch {
                needConvert = true;
            }

            const formData = new FormData();
            formData.append("audio", audioAAC);
            formData.append("audioExtension", "mp4");
            formData.append("userId", embedConfig.userId);
            formData.append("status", embedConfig.status || "draft");
            formData.append("waveform", waveform);
            formData.append("duration", (duration || 0) * 1000);
            formData.append("needConvert", needConvert);
            formData.append("visibility", embedConfig.visibility || "public");
            if (contributorPicture) formData.append("contributorPicture", contributorPicture);
            if (embedConfig.titleMode === "input" && title) formData.append("title", title);
            if (!["disabled", "input"].includes(embedConfig.titleMode) && embedConfig.title) formData.append("title", embedConfig.title);
            if (embedConfig.descMode === "input" && desc) formData.append("desc", desc);
            if (!["disabled", "input"].includes(embedConfig.descMode) && embedConfig.desc) formData.append("desc", embedConfig.desc);
            if (embedConfig.externalId) formData.append("externalId", embedConfig.externalId);
            if (embedConfig.callback) formData.append("callback", embedConfig.callback);
            if (embedConfig.collections?.length > 0) formData.append("collections", embedConfig.collections.join(","));
            if (embedConfig.pictures) formData.append("pictures", embedConfig.pictures);

            for (const key in embedConfig.details) {
                if (embedConfig.details[key]) formData.append(`details_${key}`, embedConfig.details[key]);
            }

            const response = await axios.post(`${["localhost", "127.0.0.1"].includes(document.location.hostname) ? "http://127.0.0.1:8080" : ""}/recorder/upload`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Progression : ${percentCompleted}%`);
                    setProgress(50 + percentCompleted / 2); // Progress from 50% to 100%
                },
            });
            event_vocast_create({
                ownerId: embedConfig.userId,
                privacy: embedConfig.visibility,
                environment: "iframe",
                referrer: searchParams.get("referrer"),
                vocastId: response.data.audioDoc.id,
            });
            const data = response.data;
            if (!data.success) {
                setError(data.message);
                return;
            }

            // setTimeout(() => {
            //     loadDataAudio(data.audioDoc, userData);
            // }, 3000);

            rc.setStatus("up-finished");
        } catch (error) {
            console.error("Erreur lors de l'envoi :", error);
            rc.setStatus("up-error");
        }
    };

    const loadDataAudio = async (audioDoc, _userData) => {
        rc.setStatus("embed-pause");

        const _embedConfig = { ...embedConfig };
        _embedConfig.title = audioDoc.title;
        _embedConfig.titleMode = "default";
        _embedConfig.desc = audioDoc.desc;
        _embedConfig.descMode = "default";
        setEmbedConfig(_embedConfig);
        rc.setDuration(audioDoc.duration);
        rc.initAudioCallback();
        rc.audioRef.current.src = audioDoc.url;
        if (audioDoc.contributor) {
            setContributorPicture(true);
            setContributorPictureUrl(audioDoc.contributor.profilePicture);
        }
        setRetrieveAudio(audioDoc);
    };

    useEffect(() => {
        return () => {
            if (contributorPictureUrl) URL.revokeObjectURL(contributorPictureUrl);
        };
    }, [contributorPictureUrl]);

    const getAlphafloat = (a, alpha) => {
        if (typeof a !== "undefined") return a / 255;
        if (typeof alpha != "number" || alpha < 0 || alpha > 1) return 1;
        return alpha;
    };

    const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, "g"));

    const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

    const hexToRGBA = (hex, alpha) => {
        const chunkSize = Math.floor((hex.length - 1) / 3);
        const hexArr = getChunksFromString(hex.slice(1), chunkSize);
        const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
        return { r, g, b, a: getAlphafloat(a, alpha) };
    };

    const isDarkColor = (hex) => {
        const { r, g, b } = hexToRGBA(hex) || { r: 0, g: 0, b: 0 };
        return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) <= 127.5;
    };

    const decodeValue = (value) => {
        if (value === "true") return true;
        if (value === "false") return false;
        return value;
    };

    useEffect(() => {
        const defaultValues = {
            inputContributorPicture: true,
            allowRecordingPause: false,
            inputAudioFromFile: false,
        };

        const base = JSON.parse(window.atob(params || searchParams.get("p") || "e30="));
        const override = { ...base };
        searchParams.forEach((value, key) => {
            if (key === "p") return;
            override[key] = decodeValue(value);
        });
        override.logoDark = isDarkColor(override.colors?.bgColor || "#000000");

        setEmbedConfig({ ...defaultValues, ...override });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, searchParams]);

    useEffect(() => {
        if (!embedConfig.userId) return;
        // setDefaultContributorPicture();

        getDoc(doc(db, "users", embedConfig.userId)).then((docRef) => {
            if (!docRef.exists()) return;
            const _userData = docRef.data();
            setUserData(_userData);

            // Event page recorder
            if (!demo) {
                event_page_recorder({
                    ownerId: embedConfig.userId,
                    referrer: searchParams.get("referrer"),
                    environment: "iframe",
                    extraCallback: embedConfig.callback,
                    extraExternalId: embedConfig.externalId,
                    extraAllowPause: embedConfig.allowRecordingPause,
                });
            }

            if (!embedConfig.externalId) return setLoading(false);
            getDocs(query(collection(db, "audio"), where("externalId", "==", embedConfig.externalId), where("ownerId", "==", embedConfig.userId)))
                .then((docs) => {
                    if (docs.docs.length === 0) return rc.setStatus("idle");
                    rc.setStatus("already-published");
                    // loadDataAudio(docs.docs[0].data(), _userData);
                })
                .finally(() => setLoading(false));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [embedConfig.userId, embedConfig.externalId]);

    useEffect(() => {
        if (loading || error || userData === null) return;
        const resizeObserver = new ResizeObserver((entries) => {
            window.parent.postMessage({ type: `resize-vocast${searchParams.get("linkId") || ""}`, height: entries[0].contentRect.height + (embedConfig.showBorder ? 10 : 0) }, "*");
        });
        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, userData]);

    useEffect(() => {
        if (!buttonContainerRef.current) return;

        const checkButtonContainerWidth = () => {
            if (buttonContainerRef.current) {
                const containerWidth = buttonContainerRef.current.getBoundingClientRect().width;
                setShowButtonText(containerWidth > 325); // Adjust threshold as needed
            }
        };

        const resizeObserver = new ResizeObserver(checkButtonContainerWidth);
        resizeObserver.observe(buttonContainerRef.current);

        checkButtonContainerWidth(); // Initial check

        return () => {
            if (buttonContainerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                resizeObserver.unobserve(buttonContainerRef.current);
            }
            resizeObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonContainerRef.current]);

    if (!inApp) document.body.style.backgroundColor = "transparent";
    const background = {
        vocast: `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.3)), url(${defaultBg})`,
        no: null,
        yes: `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.3)), url(${userData?.profilePicture})`,
    }[embedConfig.background];

    const show = ["up-progress", "up-finished", "up-error", "already-published", "not-allowed"].includes(rc.status) || loading;
    const recOrRev = !rc.status.startsWith("rec-") && !rc.status.startsWith("rev-");

    return (
        <Container fluid style={{ width: "100%", paddingLeft: !embedConfig.showBorder ? 0 : ".2rem", paddingRight: !embedConfig.showBorder ? 0 : ".2rem", position: "relative" }}>
            {/* <div>
                <input type="radio" name="status" value="idle" checked={rc.status === "idle"} onChange={() => rc.setStatus("idle")} /> Idle
                <input type="radio" name="status" value="not-allowed" checked={rc.status === "not-allowed"} onChange={() => rc.setStatus("not-allowed")} /> Not allowed
                <input type="radio" name="status" value="rec-pause" checked={rc.status === "rec-pause"} onChange={() => rc.setStatus("rec-pause")} /> Rec pause
                <input type="radio" name="status" value="rec-play" checked={rc.status === "rec-play"} onChange={() => rc.setStatus("rec-play")} /> Rec play
                <input type="radio" name="status" value="rev-pause" checked={rc.status === "rev-pause"} onChange={() => rc.setStatus("rev-pause")} /> Rev pause
                <input type="radio" name="status" value="rev-play" checked={rc.status === "rev-play"} onChange={() => rc.setStatus("rev-play")} /> Rev play
                <input type="radio" name="status" value="up-progress" checked={rc.status === "up-progress"} onChange={() => rc.setStatus("up-progress")} /> Up progress
                <input type="radio" name="status" value="up-finished" checked={rc.status === "up-finished"} onChange={() => rc.setStatus("up-finished")} /> Up finished
                <input type="radio" name="status" value="up-error" checked={rc.status === "up-error"} onChange={() => rc.setStatus("up-error")} /> Up error
                <input type="radio" name="status" value="already-published" checked={rc.status === "already-published"} onChange={() => rc.setStatus("already-published")} /> Already published
                <input type="radio" name="status" value="embed-pause" checked={rc.status === "embed-pause"} onChange={() => rc.setStatus("embed-pause")} /> Embed pause
                <input type="radio" name="status" value="embed-play" checked={rc.status === "embed-play"} onChange={() => rc.setStatus("embed-play")} /> Embed play
            </div> */}
            {error && (
                <Alert
                    show={error}
                    variant="danger"
                    // onClose={() => setError(false)}
                    // dismissible
                    style={{
                        position: "absolute",
                        top: 16,
                        right: "50%",
                        transform: "translateX(50%)",
                        zIndex: 1000,
                        padding: "0.5rem 1rem",
                        margin: 5,
                        backgroundColor: "rgba(220, 53, 69, 0.9)",
                        color: "#fff",
                    }}
                >
                    {error}
                </Alert>
            )}
            <Row style={{ padding: 0, margin: !embedConfig.showBorder ? 0 : 5, height: "fit-content" }}>
                <Col
                    xs={12}
                    style={{
                        borderRadius: "1.3em",
                        padding: "1em",
                        paddingBottom: 0,
                        // border: "1px solid #000",
                        boxShadow: !embedConfig.showBorder ? null : "0px 0px 5px 0px rgba(0,0,0,0.4)",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: embedConfig.colors?.bgColor,
                        backgroundImage: background,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {embedConfig.showProfile && (
                        <div className="link-btn" style={{ height: 50 }}>
                            <div className="align-items-center" style={{ display: "flex" }}>
                                <div style={{ width: "auto", marginRight: "14px" }}>
                                    {userData?.profilePicture && (
                                        <a href={`${window.location.origin}/${userData?.nickname}`} target="_blank" rel="noopener noreferrer">
                                            <img
                                                alt={userData?.name}
                                                src={userData?.profilePicture}
                                                style={{ width: "100%", borderRadius: "42%", aspectRatio: 1, objectFit: "cover", backgroundPosition: "center", maxWidth: 50, height: "100%" }}
                                            />
                                        </a>
                                    )}
                                </div>
                                <div>
                                    <h4 className="m-0" style={{ fontSize: 19 }}>
                                        <a
                                            href={`${window.location.origin}/${userData?.nickname}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: "none", color: embedConfig.colors?.textColor || "#fff", fontWeight: 600 }}
                                        >
                                            {userData?.name}
                                        </a>
                                    </h4>
                                    <div>
                                        <a
                                            href={`${window.location.origin}/${userData?.nickname}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: "none", color: embedConfig.colors?.textColor || "#fff" }}
                                        >
                                            <small>@{userData?.nickname}</small>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Card
                        style={{
                            marginTop: "1rem",
                            width: "100%",
                            borderRadius: "1.2em",
                            backgroundColor: embedConfig.colors?.cardColor || "rgba(51, 51, 51, 0.8)",
                            border: embedConfig.showBorder ? "none" : "1px solid rgba(0, 0, 0, 0.175)",
                            minHeight: 80,
                        }}
                    >
                        <Card.Body style={{ paddingTop: true ? "1rem" : ".5rem", paddingBottom: true ? "1rem" : ".5rem", paddingLeft: "1rem", paddingRight: "1rem", position: "relative" }}>
                            <div className={`indicator-circle ${rc.status}`}>{t(`Record:${rc.status}`)}</div>
                            {!show && (
                                <div style={{ display: "flex", gap: 10, flexDirection: "column" }} className="animate-opacity">
                                    {embedConfig.titleMode === "input" && (
                                        <h1 className="text-truncate m-0" style={{ height: 29 }}>
                                            <input
                                                type="text"
                                                value={title}
                                                placeholder={embedConfig.title || t("Record:title")}
                                                onChange={(e) => setTitle(e.target.value)}
                                                style={{
                                                    width: "100%",
                                                    border: "none",
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    backgroundColor: "transparent",
                                                    color: embedConfig.colors?.cardContentColor || "#fff",
                                                    fontSize: "1.2rem",
                                                    fontWeight: "600",
                                                }}
                                                maxLength={50}
                                                className="no-outline"
                                            />
                                        </h1>
                                    )}
                                    {embedConfig.titleMode === "default" && embedConfig.title && (
                                        <h1 className="text-truncate m-0" style={{ height: 29 }}>
                                            <p className="link-btn" style={{ fontSize: "calc(1rem + 0.1vw)", color: embedConfig.colors?.cardContentColor || "#fff" }}>
                                                {embedConfig.title}
                                            </p>
                                        </h1>
                                    )}

                                    {embedConfig.descMode === "input" && (
                                        <textarea
                                            value={desc}
                                            className="no-outline"
                                            placeholder={embedConfig.desc || t("Record:desc")}
                                            onChange={(e) => setDesc(e.target.value)}
                                            style={{
                                                width: "100%",
                                                border: "none",
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                backgroundColor: "transparent",
                                                color: embedConfig.colors?.cardContentColor || "#fff",
                                                fontSize: "1rem",
                                                resize: "none",
                                                overflow: "hidden",
                                            }}
                                            rows={2}
                                        />
                                    )}
                                    {embedConfig.descMode === "default" && embedConfig.desc && (
                                        <p className="link-btn" style={{ color: embedConfig.colors?.cardContentColor || "#fff", marginBottom: 0 }}>
                                            {embedConfig.desc}
                                        </p>
                                    )}
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        {(embedConfig.inputContributorPicture || embedConfig.inputVocasteurPicture) && (
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ position: "relative", cursor: "pointer" }}
                                                onMouseEnter={() => setActiveClass(true)}
                                                onMouseLeave={() => setActiveClass(false)}
                                            >
                                                <Tip
                                                    active={activeClass && !isMobile}
                                                    text={t(`Record:${!contributorPicture ? "addContributor" : "changeContributor"}`)}
                                                    position="down left"
                                                    style={{ fontSize: 14, display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}
                                                    inside={embedConfig.colors?.bgColor || "#000"}
                                                    outer={embedConfig.colors?.textColor || "#80ffd7"}
                                                >
                                                    {!contributorPicture ? (
                                                        <UploadContributor
                                                            style={{ width: 42, height: 42, cursor: "pointer", color: embedConfig.colors?.cardContentColor || "#fff" }}
                                                            onClick={() => askForContributorPicture()}
                                                        />
                                                    ) : (
                                                        <>
                                                            <img alt={userData?.name} src={contributorPictureUrl} style={{ width: 42, height: 42, borderRadius: "50%", cursor: "pointer", objectFit: "cover" }} />
                                                            {!rc.status.startsWith("embed-") && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: "50%",
                                                                        transform: "translateX(-50%)",
                                                                        width: 42,
                                                                        height: 42,
                                                                        borderRadius: "50%",
                                                                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                                                                    }}
                                                                    onClick={() => askForContributorPicture()}
                                                                >
                                                                    <FaXmark
                                                                        style={{ color: embedConfig.colors?.cardContentColor || "#fff", fontSize: 18, position: "absolute", top: -5, right: -8, width: 22, height: 22 }}
                                                                        onClick={(e) => deleteContributorPicture(e)}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    <span style={{ color: embedConfig.colors?.cardContentColor || "#fff", fontSize: 11, whiteSpace: "nowrap" }}>{t("Record:yourPicture")}</span>
                                                </Tip>
                                            </div>
                                        )}
                                        <div style={{ minWidth: 46, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 4 }}>
                                            <p style={{ color: embedConfig.colors?.cardContentColor || "#fff", fontSize: 14, marginBottom: 0 }}>
                                                {rc.currentTime ? moment(rc.currentTime * 1000).format("mm:ss") : moment(rc.duration).format("mm:ss")}
                                            </p>
                                            {rc.status === "rec-play" && <div className="recording-indicator" />}
                                        </div>
                                        <div style={{ width: "100%", overflow: "hidden" }}>
                                            <WaveForm
                                                waveform={retrieveAudio?.waveform}
                                                duration={rc.duration}
                                                audioBlob={rc.audio}
                                                mediaRecorder={rc.mediaRecorderRef.current}
                                                currentTime={rc.currentTime}
                                                setWarning={setWarning}
                                                isLive={rc.status.startsWith("rec-play")}
                                                empty
                                                color={embedConfig.colors?.cardContentColor || "#80ffd7"}
                                                height={48}
                                            />
                                            <audio ref={rc.audioRef} controls autoPlay src={rc.audioUrl} style={{ display: "none" }} id="re-embed-audio" />
                                        </div>
                                        {embedConfig.inputAudioFromFile && rc.status === "idle" && (
                                            <div style={{ minWidth: 28, padding: 4, cursor: "pointer" }} onClick={askForAudioFile}>
                                                <FaUpload size={20} style={{ color: embedConfig.colors?.cardContentColor || "#fff" }} fill={embedConfig.colors?.cardContentColor || "#fff"} />
                                            </div>
                                        )}
                                        <div style={{ minWidth: 48 }}>
                                            <Tip
                                                active={rc.status === "idle"}
                                                text={embedConfig.clickToRecord || t("Record:clickToRecord")}
                                                position="down right"
                                                style={{ fontSize: 14 }}
                                                inside={embedConfig.colors?.bgColor || "#000"}
                                                outer={embedConfig.colors?.textColor || "#80ffd7"}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <Btn
                                                        {...rc}
                                                        inside={embedConfig.colors?.cardColor}
                                                        outer={embedConfig.colors?.cardContentColor}
                                                        allowRecordingPause={embedConfig.allowRecordingPause}
                                                        handleStopSimple={handleStopSimple}
                                                    />
                                                </div>
                                            </Tip>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {show && (
                                <div
                                    className="animate-opacity"
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        gap: 8,
                                        display: "flex",
                                    }}
                                >
                                    {rc.status === "up-progress" && !isDownloadingPictures && (
                                        <div style={{ fontSize: 14, color: embedConfig.colors?.cardContentColor || "#fff" }}>{`${t("Record:uploading")} - ${parseInt(progress)}%`}</div>
                                    )}
                                    {rc.status === "up-progress" && isDownloadingPictures && (
                                        <div style={{ fontSize: 14, color: embedConfig.colors?.cardContentColor || "#fff" }}>{`${t("Record:processing")} - ${parseInt(progress)}%`}</div>
                                    )}
                                    {rc.status === "up-finished" && (
                                        <>
                                            <FaRegCircleCheck size={32} style={{ color: embedConfig.colors?.cardContentColor || "#fff" }} />
                                            <div style={{ fontSize: 14, color: embedConfig.colors?.cardContentColor || "#fff", textAlign: "center" }}>{embedConfig.afterUpload || t("Record:uploadFinished")}</div>
                                        </>
                                    )}
                                    {rc.status === "up-error" && <div style={{ fontSize: 14, color: embedConfig.colors?.cardContentColor || "#fff" }}>{t("Record:uploadError")}</div>}
                                    {rc.status === "up-progress" && <ProgressBar progress={progress} style={{ width: "100%", maxWidth: 300 }} color={embedConfig.colors?.cardContentColor || "#80ffd7"} />}
                                    {rc.status === "up-error" && (
                                        <Button
                                            variant="outline-dark"
                                            type="button"
                                            className="px-3 py-1 d-flex align-items-center gap-2 animate-opacity"
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                height: 24,
                                                display: "flex",
                                                color: embedConfig.colors?.cardContentColor || "#fff",
                                                backgroundColor: embedConfig.colors?.cardColor || "rgba(51, 51, 51, 0.8)",
                                                border: `1px solid ${embedConfig.colors?.cardColor || "rgba(51, 51, 51, 0.8)"}`,
                                            }}
                                            onClick={() => window.location.reload()}
                                        >
                                            <FaRotateLeft size={18} style={{ color: "inherit" }} />
                                            {t(`Record:reset`)}
                                        </Button>
                                    )}
                                    {rc.status === "not-allowed" && (
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 8 }}>
                                            <FaMicrophoneSlash size={32} style={{ color: embedConfig.colors?.cardContentColor || "#fff" }} />
                                            <div style={{ fontSize: 14, color: embedConfig.colors?.cardContentColor || "#fff" }}>{t("Record:notAllowed")}</div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div
                                className="animate-opacity"
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: 8,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    opacity: rc.status === "already-published" ? 1 : 0,
                                    display: rc.status === "already-published" ? "flex" : "none",
                                }}
                            >
                                <div style={{ fontSize: 16, color: embedConfig.colors?.cardContentColor || "#fff", textAlign: "center" }}>{t("Record:alreadyPublished")}</div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Row style={{ height: 66 }}>
                        <Col xs={12} className={`d-flex justify-content-between align-items-center`} ref={buttonContainerRef}>
                            <a href={`${window.location.origin}`} target="_blank" rel="noopener noreferrer" style={{ display: "flex" }}>
                                <img alt="Vocast" src={embedConfig.logoDark ? logoWhite : logoBlack} style={{ width: "auto", height: 60 }} />
                            </a>
                            <div className="d-flex align-items-center gap-2">
                                {!loading && !recOrRev && (
                                    <Button
                                        variant="outline-dark"
                                        type="button"
                                        className="px-3 py-1 d-flex align-items-center gap-2 animate-opacity"
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            height: 34,
                                            color: embedConfig.colors?.bgColor || "#000",
                                            backgroundColor: embedConfig.colors?.textColor || "#80ffd7",
                                            border: `1px solid ${embedConfig.colors?.textColor || "#80ffd7"}`,
                                        }}
                                        disabled={recOrRev || rc.status.startsWith("rec-play")}
                                        onClick={() => rc.handleReset()}
                                    >
                                        <FaRotateLeft size={18} style={{ color: "inherit" }} />
                                        {/* <IsLarger width={650}>{t(`Record:reset`)}</IsLarger> */}
                                    </Button>
                                )}
                                {!loading && !recOrRev && (
                                    <Button
                                        variant="outline-dark"
                                        type="button"
                                        className="px-3 py-1 d-flex align-items-center gap-2 animate-opacity"
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            height: 34,
                                            color: embedConfig.colors?.bgColor || "#000",
                                            backgroundColor: embedConfig.colors?.textColor || "#80ffd7",
                                            border: `1px solid ${embedConfig.colors?.textColor || "#80ffd7"}`,
                                        }}
                                        disabled={recOrRev || rc.status.startsWith("rec-play")}
                                        onClick={rc.status.startsWith("rec-") ? (embedConfig.allowRecordingPause ? rc.handleStop : handleStopSimple) : handleSave}
                                    >
                                        {embedConfig.allowRecordingPause ? (
                                            rc.status.startsWith("rec-") ? (
                                                <FaPlay size={18} style={{ color: "inherit" }} />
                                            ) : (
                                                <FaCircleCheck size={18} style={{ color: "inherit" }} />
                                            )
                                        ) : (
                                            <FaCircleCheck size={18} style={{ color: "inherit" }} />
                                        )}
                                        {showButtonText &&
                                            (embedConfig.allowRecordingPause ? t(`Record:${rc.status.startsWith("rec-") ? "preview" : "save"}`) : t(`Record:${rc.status.startsWith("rec-") ? "save" : "publish"}`))}
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
