import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useMatch } from "react-router-dom";

import { useUserContext } from "../context/UserContext";

import { FuturPopin } from "../components/FuturPopin";
import { IsAuth, IsLarger, IsNotAuth, IsSmaller } from "../components/Responsive";

import VocastLogo from "../Assets/img/logo-white.png";
import { ReactComponent as BellIconRegular } from "../Assets/pictos/bell-regular.svg";
import { ReactComponent as ChartAreaIconRegular } from "../Assets/pictos/chart-area-regular.svg";
import { ReactComponent as ChartAreaIconSolid } from "../Assets/pictos/chart-area-solid.svg";
import { ReactComponent as CollectionIconRegular } from "../Assets/pictos/collection-regular.svg";
import { ReactComponent as CollectionIconSolid } from "../Assets/pictos/collection-solid.svg";
import { ReactComponent as EnvelopeIconRegular } from "../Assets/pictos/envelope-regular.svg";
import { ReactComponent as EnvelopeIconSolid } from "../Assets/pictos/envelope-solid.svg";
import { ReactComponent as HomeIconRegular } from "../Assets/pictos/home-regular.svg";
import { ReactComponent as HomeIconSolid } from "../Assets/pictos/home-solid.svg";
import MicroVocastLogo from "../Assets/pictos/micro-vocast.png";
import { ReactComponent as MicrophoneIconRegular } from "../Assets/pictos/microphone-regular.svg";
import { ReactComponent as MicrophoneIconSolid } from "../Assets/pictos/microphone-solid.svg";
import { ReactComponent as SearchIconRegular } from "../Assets/pictos/search-regular.svg";
import { ReactComponent as SearchIconSolid } from "../Assets/pictos/search-solid.svg";
import { ReactComponent as SettingsIconRegular } from "../Assets/pictos/settings-regular.svg";
import { ReactComponent as SettingsIconSolid } from "../Assets/pictos/settings-solid.svg";
import { ReactComponent as UserLightIcon } from "../Assets/pictos/user-circle-light.svg";
import { ReactComponent as UserIconRegular } from "../Assets/pictos/user-regular.svg";
import { ReactComponent as UserIconSolid } from "../Assets/pictos/user-solid.svg";
import { ReactComponent as VocasterIconRegular } from "../Assets/pictos/users-light.svg";
import { ReactComponent as VocasterIconSolid } from "../Assets/pictos/users-solid.svg";
import { ReactComponent as VocastIcon } from "../Assets/pictos/vocast.svg";

import { MdLogout } from "react-icons/md";
import { TbWorld } from "react-icons/tb";

export const icons = {
    Home: { regular: HomeIconRegular, solid: HomeIconSolid },
    Search: { regular: SearchIconRegular, solid: SearchIconSolid },
    Envelope: { regular: EnvelopeIconRegular, solid: EnvelopeIconSolid },
    Collection: { regular: CollectionIconRegular, solid: CollectionIconSolid },
    Vocaster: { regular: VocasterIconRegular, solid: VocasterIconSolid },
    Microphone: { regular: MicrophoneIconRegular, solid: MicrophoneIconSolid },
    ChartArea: { regular: ChartAreaIconRegular, solid: ChartAreaIconSolid },
    Settings: { regular: SettingsIconRegular, solid: SettingsIconSolid },
    User: { regular: UserIconRegular, solid: UserIconSolid },
    Logout: { regular: MdLogout, solid: MdLogout },
};

const NavLinkCustom = ({ to, title, icon, onClick }) => {
    const isActive = useMatch(to);
    const { t } = useTranslation();

    const Icon = isActive ? icons[icon]?.solid : icons[icon]?.regular;

    return (
        <NavLink to={to} style={{ color: "#fff", fontSize: 16, textDecoration: "none", fontWeight: isActive ? 700 : 400, display: "flex", alignItems: "center", gap: 8 }} className="qcont" onClick={onClick}>
            {Icon && (
                <IsLarger width={940}>
                    <Icon height={20} style={{ color: "#fff", minWidth: 20, marginRight: 8, marginLeft: 8 }} fill="#fff" />
                </IsLarger>
            )}
            {Icon && (
                <IsSmaller width={940}>
                    <Icon height={20} style={{ color: "#fff", minWidth: 20, marginLeft: 13 }} fill="#fff" />
                </IsSmaller>
            )}
            <IsLarger width={940}>{t(`BO:${title}_title`)}</IsLarger>
        </NavLink>
    );
};

export const DropdownItemCustom = ({ to, title, icon, onClick }) => {
    const isActive = useMatch(to);
    const { t } = useTranslation();

    const Icon = isActive ? icons[icon]?.solid : icons[icon]?.regular;

    return (
        <Dropdown.Item
            to={to}
            style={{ color: "#fff", fontSize: 16, textDecoration: "none", fontWeight: isActive ? 700 : 400, display: "flex", alignItems: "center", marginBottom: 3, marginTop: 3 }}
            className="qcont"
            onClick={onClick}
            as={NavLink}
        >
            <Icon height={20} style={{ color: "#fff", marginRight: 8, height: 20, minWidth: 20 }} fill="#fff" />
            {t(title.includes(":") ? title : `TopBar:${title}`)}
        </Dropdown.Item>
    );
};

const Layout = ({ children }) => {
    const { t, i18n } = useTranslation();
    const { user, userData, logout } = useUserContext();
    const [minimal, setMinimal] = useState(false);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const boRight = document.getElementById("bo-right");
        const boLeft = document.getElementById("bo-left");
        const boContent = document.getElementById("bo-content");
        if (!boRight || !boLeft || !boContent) return;

        const handleScroll = (e) => {
            e.preventDefault();
            e.stopPropagation();
            boContent.scrollTop += e.deltaY;
        };

        boRight.addEventListener("wheel", handleScroll);
        boLeft.addEventListener("wheel", handleScroll);

        return () => {
            boRight.removeEventListener("wheel", handleScroll);
            boLeft.removeEventListener("wheel", handleScroll);
        };
    }, [minimal]);

    const openModal = (e, modal) => {
        e.preventDefault();
        e.stopPropagation();
        setModal(modal);
    };

    const handleLogout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        logout();
    };

    const maxWidth = window.location.pathname.includes("/admin") ? 1162 : 600;

    return (
        <>
            <FuturPopin show={modal} onHide={() => setModal(null)} />
            <IsSmaller width={650}>{children}</IsSmaller>
            <IsLarger width={650} onChange={(_state) => setMinimal(_state)}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            bottom: 0,
                            height: "100vh",
                            flex: 1,
                            backgroundColor: "black",
                            padding: 20,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            paddingTop: 18,
                            zIndex: 100,
                        }}
                        id="bo-left"
                    >
                        <div>
                            <div>
                                <a href="https://www.vocast.co" target="_blank" rel="noreferrer">
                                    <IsLarger width={940}>
                                        <img src={VocastLogo} style={{ height: 41, width: "auto", margin: "4px 0 0 10px" }} alt="Vocast" />
                                    </IsLarger>
                                    <IsSmaller width={940}>
                                        <img src={MicroVocastLogo} style={{ height: 51, width: "auto" }} alt="Vocast" />
                                    </IsSmaller>
                                </a>
                            </div>
                            <IsAuth>
                                <div style={{ marginTop: 40, display: "flex", flexDirection: "column", gap: 16, width: "min-content" }}>
                                    <NavLink
                                        to={`/new`}
                                        style={{
                                            padding: "10px 15px",
                                            textDecoration: "none",
                                            color: "black",
                                            fontWeight: 600,
                                            backgroundColor: "#80fed8",
                                            borderRadius: 15,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            whiteSpace: "nowrap",
                                            gap: 8,
                                        }}
                                        onClick={(e) => openModal(e, "new")}
                                    >
                                        <VocastIcon height={20} />
                                        <IsLarger width={940}>{t("BO:new_title")}</IsLarger>
                                    </NavLink>
                                    <NavLinkCustom to={`/feed`} title="feed" icon="Home" onClick={(e) => openModal(e, "feed")} />
                                    <NavLinkCustom to={`/search`} title="search" icon="Search" onClick={(e) => openModal(e, "search")} />
                                    <NavLinkCustom to={`/messages`} title="messages" icon="Envelope" onClick={(e) => openModal(e, "messages")} />
                                    <NavLinkCustom to={`/posts`} title="posts" icon="Microphone" onClick={(e) => openModal(e, "vocasts")} />
                                    <NavLinkCustom to={`/my-collections`} title="collections" icon="Collection" onClick={(e) => openModal(e, "collections")} />
                                    <NavLinkCustom to={`/vocasters`} title="vocasters" icon="Vocaster" onClick={(e) => openModal(e, "vocasters")} />
                                    <NavLinkCustom to={`/analytics`} title="analytics" icon="ChartArea" onClick={(e) => openModal(e, "analytics")} />
                                </div>
                            </IsAuth>
                        </div>
                        <div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 16, width: "min-content" }}>
                                <IsAuth>
                                    <NavLinkCustom to={`/settings`} title="settings" icon="Settings" onClick={(e) => openModal(e, "settings")} />
                                </IsAuth>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        style={{
                                            background: "none",
                                            color: "#fff",
                                            fontSize: 14,
                                            borderRadius: 50,
                                            borderColor: "#fff",
                                        }}
                                    >
                                        <TbWorld size={20} style={{ marginRight: 8 }} />
                                        <IsLarger width={940}>{t(`common:lang.${i18n.language.split("-").at(0)}`)}</IsLarger>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ backgroundColor: "#000", border: "1px solid #fff" }}>
                                        {i18n.options.supportedLngs
                                            .filter((v) => v !== "cimode")
                                            .map((lang) => (
                                                <Dropdown.Item
                                                    key={lang}
                                                    style={{
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                    onClick={() => i18n.changeLanguage(lang)}
                                                >
                                                    {t(`common:lang.${lang.split("-").at(0)}`)}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxWidth: maxWidth, width: "100%", overflow: "hidden" }}>{children}</div>
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            bottom: 0,
                            height: "100vh",
                            flex: 1,
                            backgroundColor: "black",
                            padding: 20,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            zIndex: 100,
                        }}
                        id="bo-right"
                    >
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 4, flexWrap: "wrap-reverse" }}>
                            <IsNotAuth>
                                <IsLarger width={1153}>
                                    <Link to="/login" style={{ padding: "12px 20px 12px 0", color: "#80ffd7", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14, textDecoration: "none" }}>
                                        {t("Login:login")}
                                    </Link>
                                    <Link to="/signup" className="btn btn-primary" style={{ padding: "12px 20px", backgroundColor: "#80ffd7", color: "#000", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14 }}>
                                        {t("Login:register")}
                                    </Link>
                                </IsLarger>
                                <IsSmaller width={1153}>
                                    <Link to="/signup" style={{ padding: "8px", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14 }}>
                                        <UserLightIcon height={36} fill="#fff" />
                                    </Link>
                                </IsSmaller>
                            </IsNotAuth>
                            <IsAuth>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ marginRight: 20 }}>
                                        <BellIconRegular height={25} fill="#fff" />
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdown-basic"
                                            className="dropdown-toggle-custom"
                                            style={{
                                                background: "none",
                                                border: "none",
                                                padding: 0,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                                {userData?.profilePicture || user?.profilePicture ? (
                                                    <img
                                                        src={userData?.profilePicture || user?.profilePicture}
                                                        alt="Profile"
                                                        style={{
                                                            height: 36,
                                                            width: 36,
                                                            borderRadius: "50%",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                ) : (
                                                    <UserLightIcon height={36} fill="#fff" />
                                                )}
                                                <IsLarger width={940}>
                                                    <div style={{ marginLeft: 10, textAlign: "left" }}>
                                                        <p className="mb-0" style={{ color: "#fff", fontSize: 16 }}>
                                                            {userData?.name}
                                                        </p>
                                                        <p className="mb-0" style={{ color: "#aaa", fontSize: 14 }}>
                                                            @{userData?.nickname}
                                                        </p>
                                                    </div>
                                                </IsLarger>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ backgroundColor: "#000", border: "1px solid #fff" }}>
                                            <DropdownItemCustom to={`/${userData?.nickname}`} title="seeProfile" icon="User" />
                                            <DropdownItemCustom to="/logout" title="logout" icon="Logout" onClick={(e) => handleLogout(e)} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </IsAuth>
                        </div>
                        <IsLarger width={940}>
                            <div className="" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div>
                                    <div className="d-flex gap-3 flex-wrap" style={{ justifyContent: "flex-end", paddingLeft: 10 }}>
                                        <a href="https://apps.apple.com/fr/app/vocast-capsules-vocales/id6450795563">
                                            <IsLarger width={1220}>
                                                <img
                                                    src={`https://storage.googleapis.com/vocast-public/assets/AppStore/${i18n.language?.split("-").at(0)}.svg`}
                                                    alt="App Store"
                                                    style={{
                                                        height: 40,
                                                    }}
                                                />
                                            </IsLarger>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.vocast">
                                            <IsLarger width={1220}>
                                                <img
                                                    src={`https://storage.googleapis.com/vocast-public/assets/PlayStore/${i18n.language?.split("-").at(0)}.svg`}
                                                    alt="Google Play Store"
                                                    style={{
                                                        height: 40,
                                                        borderRadius: 10,
                                                        border: "1px solid #A6A6A6",
                                                    }}
                                                />
                                            </IsLarger>
                                        </a>
                                    </div>
                                    {/* <div style={{ padding: "15px 0 0 12px" }}>
                                        <p style={{ color: "#fff", fontSize: 12, margin: 0 }}>
                                            {t("BO:function")}
                                        </p>
                                        <p style={{ color: "#fff", fontSize: 12, margin: 0 }}>
                                            {t("BO:cgu")}
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </IsLarger>
                    </div>
                </div>
            </IsLarger>
        </>
    );
};

export default Layout;
