import { memo, useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import moment from "moment";

import { GlobalContext } from "../context/GlobalContext";
import { useUserContext } from "../context/UserContext";

import { db } from "../services/firebase";
import { event_vocast_like } from "../services/stats";

import { IsAuth } from "../components/Responsive";
import { Tag } from "../components/Tag";

import { ContactPopin } from "./ContactPopin";
import Desc from "./Desc";
import { FuturPopin } from "./FuturPopin";
import { IframePopin } from "./IframePopin";
import { QRCodePopin } from "./QRCodePopin";
import { ReportPopin } from "./ReportPopin";
import WaveFormPlayer from "./WaveFormPlayer";

import { ReactComponent as CodeIconWhite } from "../Assets/pictos/code-white.svg";
import { ReactComponent as Comment } from "../Assets/pictos/comment.svg";
import { ReactComponent as ExportIcon } from "../Assets/pictos/export.svg";
import { ReactComponent as Headphones } from "../Assets/pictos/headphones.svg";
import { ReactComponent as Heart } from "../Assets/pictos/heart.svg";
import pauseMini from "../Assets/pictos/pause-mini.svg";
import playMini from "../Assets/pictos/play-mini.svg";
import { ReactComponent as ReportIcon } from "../Assets/pictos/report.svg";

import { FaTimes } from "react-icons/fa";
import { FaImage } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { HiMiniPencilSquare, HiMiniQrCode } from "react-icons/hi2";
import { IoIosMore } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const defaultUrlBg = "https://storage.googleapis.com/vocast-public/assets/background/default.jpg";
const AudioCard = memo(({ hit, user, handlePlay, setLanguage, language, languages, place }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user: currentUser } = useUserContext();
    const { audioSource, setAudioSource, audioStatus, audioPlayer } = useContext(GlobalContext);
    const [duration, setDuration] = useState(hit.duration);
    const [audioDoc, setAudioDoc] = useState(null);
    const audioPlayerRef = useRef(null);
    const [isLiked, setIsLiked] = useState(false);

    const [showImage, setShowImage] = useState(false);
    const [imageIdx, setImageIdx] = useState(0);

    const [modalChoice, setModalChoice] = useState(false);
    const [modalIframe, setModalIframe] = useState(false);
    const [modalQRCode, setModalQRCode] = useState(false);
    const [modalContact, setModalContact] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [modalFutur, setModalFutur] = useState(false);

    const [currentRate, setCurrentRate] = useState(1);

    const handleSignup = () => {
        navigate("/signup");
    };

    const handleAudioClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!e.target.closest("button")) {
            if (e.ctrlKey || e.metaKey) window.open(`/${user.nickname}/${hit.objectID}`, "_blank").focus();
            else navigate(`/${user.nickname}/${hit.objectID}`);
        }
    };

    useEffect(() => {
        if (!hit) return;
        getDoc(doc(db, "audio", hit.objectID)).then((doc) => {
            const docData = { ...doc.data(), id: doc.id };
            setAudioDoc(docData);

            // Check if current user has liked this post
            if (currentUser && docData?.likes?.hasOwnProperty?.(currentUser?.uid)) {
                setIsLiked(true);
            } else {
                setIsLiked(false);
            }
        });
    }, [hit, currentUser]);

    useEffect(() => {
        if (!audioPlayer.current) return;
        if (audioSource?.audio?.id !== audioDoc?.id) return;
        if (audioPlayer.current.playbackRate === currentRate) return;
        audioPlayer.current.playbackRate = currentRate;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioPlayer, audioSource, audioDoc, currentRate]);

    useEffect(() => {
        // If audio is playing, get current time
        if (["play", "pause"].includes(audioStatus) && audioPlayer.current && audioSource.audio.id === hit.objectID) {
            if (audioPlayerRef.current) clearInterval(audioPlayerRef.current);
            audioPlayerRef.current = setInterval(() => {
                setDuration((hit.duration || audioPlayer?.current?.duration * 1000) - (audioPlayer?.current?.currentTime ? audioPlayer.current.currentTime * 1000 : 0));
            }, 1000);
        } else {
            if (audioPlayerRef.current) clearInterval(audioPlayerRef.current);
            setDuration(hit.duration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioStatus]);

    useEffect(() => {
        if (audioPlayer.current.isLocalyListened === true) return;
        if (audioPlayer.current?.currentTime <= 0) return;
        if (hit.objectID !== audioSource.audio.id) return;

        audioPlayer.current.isLocalyListened = true;
        let listnersObject = {};
        // Clone audio variable
        let tmpAudio = JSON.parse(JSON.stringify(audioDoc));
        if (audioDoc?.listners) {
            listnersObject["listners.anonymous"] = audioDoc.listners.anonymous ? [...audioDoc.listners.anonymous, new Date()] : [new Date()];
            tmpAudio.listners.anonymous = audioDoc.listners.anonymous ? [...audioDoc.listners.anonymous, new Date()] : [new Date()];
        } else {
            listnersObject["listners.anonymous"] = [new Date()];
            if (!tmpAudio) tmpAudio = {};
            if (!tmpAudio.listners) tmpAudio.listners = {};
            tmpAudio.listners.anonymous = [new Date()];
        }

        const audioRef = doc(db, "audio", hit.objectID);
        setAudioDoc(tmpAudio);
        updateDoc(audioRef, listnersObject);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);

    useEffect(() => {
        if (!audioSource?.audio?.id || !audioDoc) return;
        const translationApiUrl = `https://us-central1-vocast-app.cloudfunctions.net/videoapp/ai/translate/${hit.objectID}/${language}`;
        if (["play", "pause"].includes(audioStatus) && audioSource.audio.id === hit.objectID) {
            audioPlayer.current.currentTime = 0;
            setAudioSource({ url: language === audioDoc.locale ? audioDoc.url : translationApiUrl, audio: audioDoc });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: `${audioDoc.title}`,
                    text: `${audioDoc.description}`,
                    url: window.location.href + `/${hit.objectID}`,
                })
                .then(() => {
                    console.log("Thanks for sharing!");
                })
                .catch(console.error);
        }
    };

    const ShowImage = () => (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: window.innerHeight,
                overflow: "hidden",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {audioDoc.pictures.length > 1 && (
                <>
                    <FaChevronLeft
                        style={{ position: "absolute", top: "50%", left: 10, color: "#fff", width: 40, height: 40, cursor: "pointer", filter: "drop-shadow( 4px 4px 4px rgba(0, 0, 0, .8))" }}
                        onClick={() => setImageIdx((mg) => (mg - 1 + audioDoc.pictures.length) % audioDoc.pictures.length)}
                    />
                    <FaChevronRight
                        style={{ position: "absolute", top: "50%", right: 10, color: "#fff", width: 40, height: 40, cursor: "pointer", filter: "drop-shadow( 4px 4px 4px rgba(0, 0, 0, .8))" }}
                        onClick={() => setImageIdx((mg) => (mg + 1) % audioDoc.pictures.length)}
                    />
                </>
            )}
            <img alt="Vocast" src={audioDoc?.pictures[imageIdx] || defaultUrlBg} style={{ maxHeight: "95%", maxWidth: "95%", objectFit: "contain" }} onClick={() => setShowImage(false)} />
            <IoClose
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "#fff",
                    width: 40,
                    height: 40,
                    cursor: "pointer",
                }}
                onClick={() => setShowImage(false)}
            />
        </div>
    );

    const ModalChoice = (props) => {
        const handleReport = () => {
            setModalReport(true);
            setModalChoice(false);
        };

        const handleFutur = () => {
            setModalFutur("settings");
            setModalChoice(false);
        };

        const handleIframe = () => {
            setModalIframe(true);
            setModalChoice(false);
        };

        const handleQRCode = () => {
            setModalQRCode(true);
            setModalChoice(false);
        };

        return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
                <Modal.Body>
                    <button
                        type="button"
                        className="btn-close"
                        style={{
                            background: "unset",
                            padding: 0,
                            width: 40,
                            height: 40,
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                        onClick={props.onHide}
                    >
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                    <IsAuth>
                        {currentUser?.uid && currentUser?.uid === audioDoc?.ownerId && (
                            <>
                                <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleFutur}>
                                    <HiMiniPencilSquare size={25} />
                                    <span>{t("common:actions:edit")}</span>
                                </div>
                                <hr />
                            </>
                        )}
                    </IsAuth>
                    {isMobile && (
                        <>
                            <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleIframe}>
                                <CodeIconWhite style={{ width: 25, height: 25 }} />
                                <span>{t("IframePopin:embedVocast")}</span>
                            </div>
                            <hr />
                            <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleQRCode}>
                                <HiMiniQrCode size={25} />
                                <span>{t("QRCodePopin:generateQRCode")}</span>
                            </div>
                            <hr />
                        </>
                    )}
                    <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleReport}>
                        <ReportIcon style={{ width: 25, height: 25 }} />
                        <span>{t("AudioCard:reportContent")}</span>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const isPlaying = audioDoc && audioStatus === "play" && audioSource && audioSource.audio && audioSource.audio.id && audioSource.audio.id === hit.objectID;
    const listners = Object.values(audioDoc?.listners || {}).reduce((total, value) => total + (Array.isArray(value) ? value.length : 1), 0);

    let profile = {};
    const isVocasterPicture = (audioDoc?.contributor?.status === "enabled" && audioDoc?.vocaster?.profilePicture) || (user?.vocasters?.[audioDoc?.vocaster] && user?.vocasters?.[audioDoc?.vocaster]?.profilePicture);
    if (audioDoc?.contributor?.status === "enabled") profile = audioDoc?.contributor;
    else if (user?.vocasters?.[audioDoc?.vocaster]) profile = user?.vocasters?.[audioDoc?.vocaster];
    else profile = user;

    const handleLike = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Redirect to signup if user is not logged in
        if (!currentUser) {
            navigate("/signup");
            return;
        }

        // Toggle like status
        const audioRef = doc(db, "audio", hit.objectID);
        const likeField = `likes.${currentUser.uid}`;

        if (isLiked) {
            // Remove like
            const updateData = {};
            updateData[likeField] = deleteField();
            await updateDoc(audioRef, updateData);

            // Update local state
            const updatedAudio = { ...audioDoc };
            if (updatedAudio.likes) {
                delete updatedAudio.likes[currentUser.uid];
                setAudioDoc(updatedAudio);
            }
            setIsLiked(false);
        } else {
            // Add like
            const updateData = {};
            updateData[likeField] = new Date();
            await updateDoc(audioRef, updateData);
            event_vocast_like({ vocastId: hit.objectID, environment: "webapp" });

            // Update local state
            const updatedAudio = { ...audioDoc };
            if (!updatedAudio.likes) updatedAudio.likes = {};
            updatedAudio.likes[currentUser.uid] = new Date();
            setAudioDoc(updatedAudio);
            setIsLiked(true);
        }
    };

    return (
        <>
            {showImage && <ShowImage />}
            <IframePopin show={modalIframe} onHide={() => setModalIframe(false)} {...{ audio: audioDoc, audioId: hit?.objectID }} />
            <ModalChoice show={modalChoice} onHide={() => setModalChoice(false)} />
            <QRCodePopin show={modalQRCode} onHide={() => setModalQRCode(false)} nickname={user?.nickname} audioId={hit?.objectID} isOwnerPicture={!!user?.profilePicture} isVocasterPicture={isVocasterPicture} />
            <ContactPopin show={modalContact} onHide={() => setModalContact(false)} {...{ context: hit?.objectID, user }} />
            <ReportPopin show={modalReport} onHide={() => setModalReport(false)} {...{ type: "audio", context: hit?.objectID, user }} />
            <FuturPopin show={modalFutur} onHide={() => setModalFutur(false)} />

            <Card
                // onClick={(e) => handleAudioClick(audio, e)}
                className="shadow mb-0 card-bg border-0"
                style={{
                    width: "100%",
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(${audioDoc?.pictures?.[0] || defaultUrlBg})`,
                    borderRadius: "2em",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#fff",
                    cursor: "pointer",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col xs={12} style={{ marginTop: 8 }}>
                            {audioDoc?.title && (
                                <Link
                                    to={`/${user.nickname}/${hit.objectID}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "#fff",
                                    }}
                                >
                                    <h1 style={{ marginRight: 40 }}>{audioDoc?.title || ""}</h1>
                                </Link>
                            )}
                            <div
                                style={{
                                    position: "absolute",
                                    right: 15,
                                    top: audioDoc?.title || audioDoc?.description ? 15 : 2,
                                    cursor: "pointer",
                                }}
                                onClick={() => setModalChoice(true)}
                            >
                                <IoIosMore size={30} />
                            </div>
                            {audioDoc?.description && (
                                <Link
                                    to={`/${user.nickname}/${hit.objectID}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "#fff",
                                        marginRight: audioDoc?.title ? 0 : 15,
                                    }}
                                    onClick={handleAudioClick}
                                >
                                    <Desc desc={audioDoc?.description || ""} />
                                </Link>
                            )}
                            <Tag collection={audioDoc?.collections} user={user} />
                            <Card
                                style={{
                                    backgroundColor: "rgba(51, 51, 51, 0.8)",
                                    color: "#fff",
                                    borderRadius: "1em",
                                    border: "none",
                                    backdropFilter: "blur(10px)",
                                    zIndex: 10 + (place[1] - place[0]),
                                    width: "100%",
                                    marginTop: 16,
                                }}
                            >
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        {profile?.profilePicture && (
                                            <div>
                                                <img
                                                    src={profile.profilePicture || defaultUrlBg}
                                                    alt={profile.name || "Vocast"}
                                                    style={{
                                                        width: 48,
                                                        height: 48,
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {(duration > 0 || isPlaying) && (
                                            <div style={{ display: "flex", gap: 1, alignItems: "center", flexDirection: "column" }}>
                                                <div style={{ fontSize: 12, width: 46, display: "flex", justifyContent: "center", alignItems: "center" }}>{duration && moment(duration).format("mm:ss")}</div>
                                                {isPlaying && (
                                                    <div
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: "bold",
                                                            cursor: "pointer",
                                                            padding: 3,
                                                            background: "radial-gradient(rgba(255, 255, 255, 0.3), transparent)",
                                                            borderRadius: 30,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            userSelect: "none",
                                                            width: 46,
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const playbackRates = [1, 1.2, 1.5, 2];
                                                            setCurrentRate(playbackRates[(playbackRates.indexOf(currentRate) + 1) % playbackRates.length]);
                                                        }}
                                                    >
                                                        <FaTimes size={10} />
                                                        {currentRate}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div style={{ width: "65%", overflow: "hidden" }}>
                                            <WaveFormPlayer audio={audioDoc} audioPlayer={audioPlayer} handlePlay={handlePlay} />
                                        </div>
                                        <div style={{}} className="d-flex justify-content-center">
                                            <Dropdown data-bs-theme="dark" variant="dark">
                                                <Dropdown.Toggle
                                                    variant="link"
                                                    className="p-0 m-0"
                                                    id="dropdown-basic"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#fff",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {/* <div style={{ fontWeight: "bold" }}>{language?.toUpperCase?.()}</div> */}
                                                    <img src={`https://storage.googleapis.com/vocast-public/assets/flags/${language}.png`} alt={language} style={{ width: 20, height: 20 }} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu
                                                    style={{
                                                        backgroundColor: "#121212",
                                                    }}
                                                >
                                                    {languages.map((lng) => (
                                                        <Dropdown.Item
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                color: "#fff",
                                                                fontWeight: "bold",
                                                                gap: 4,
                                                            }}
                                                            onClick={() => setLanguage(lng)}
                                                            key={`embed-${lng}-${hit.objectID}`}
                                                        >
                                                            <img
                                                                src={`https://storage.googleapis.com/vocast-public/assets/flags/${lng}.png`}
                                                                alt={lng}
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                }}
                                                            />
                                                            {lng.toUpperCase()} {lng !== audioDoc?.locale && "(✨IA)"}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div style={{}}>
                                            <Button variant="empty" size="sm" onClick={(e) => handlePlay(audioDoc)} className="p-0 m-0">
                                                {isPlaying ? (
                                                    <img
                                                        alt="pause"
                                                        src={pauseMini}
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                        }}
                                                    />
                                                ) : audioStatus === "loading" && audioSource.audio.id && audioSource.audio.id === hit.objectID ? (
                                                    <div
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                        }}
                                                        className="d-flex justify-content-center align-items-center"
                                                    >
                                                        <Spinner
                                                            variant="light"
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        alt="play"
                                                        src={playMini}
                                                        style={{
                                                            width: "48px",
                                                            height: "48px",
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-center mt-2" style={{}}>
                        <div className="d-flex align-items-center" style={{ gap: isDesktop ? 24 : 16, marginLeft: isDesktop ? 12 : 4 }}>
                            <div className="bluredIconButton nfd d-flex gap-2 m-0 align-items-center p-0" style={{ cursor: "default" }} onClick={handleAudioClick}>
                                <Headphones alt="Listens" style={{ maxWidth: 18 }} />
                                <span style={{ marginTop: 1 }}>{listners}</span>
                            </div>
                            <div className="bluredIconButton nfd d-flex gap-2 m-0 align-items-center p-0" style={{ cursor: "pointer" }} onClick={handleLike}>
                                <Heart alt="likes" style={{ maxWidth: 18, color: isLiked ? "#ff4d4d" : "#fff" }} />
                                <span style={{ marginTop: 1 }}>{audioDoc?.likes ? Object.keys(audioDoc.likes).length : 0}</span>
                            </div>
                            {audioDoc?.comments && Object.keys(audioDoc.comments).length > 0 && (
                                <div className="bluredIconButton nfd d-flex gap-2 m-0 align-items-center p-0" style={{ cursor: "default" }} onClick={handleAudioClick}>
                                    <Comment alt="comments" style={{ maxWidth: 18 }} />
                                    <span style={{ marginTop: 1 }}>{audioDoc?.comments ? Object.keys(audioDoc.comments).length : 0}</span>
                                </div>
                            )}
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                            {audioDoc?.pictures?.length ? (
                                <div
                                    style={{
                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                        width: 40,
                                        height: 40,
                                    }}
                                    className="d-flex justify-content-center align-items-center bluredIconButton mini m-0"
                                >
                                    <button
                                        style={{
                                            textDecoration: "none",
                                            color: "#fff",
                                            background: "transparent",
                                            border: "none",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setImageIdx(0);
                                            setShowImage(true);
                                        }}
                                    >
                                        <FaImage size={18} />
                                    </button>
                                </div>
                            ) : null}

                            {!isMobile && (
                                <div
                                    style={{
                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                        width: 40,
                                        height: 40,
                                    }}
                                    className="d-flex justify-content-center align-items-center bluredIconButton mini m-0"
                                >
                                    <button
                                        style={{
                                            textDecoration: "none",
                                            color: "#fff",
                                            background: "transparent",
                                            border: "none",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModalIframe(true);
                                        }}
                                    >
                                        <CodeIconWhite style={{ width: 18, height: 18 }} />
                                    </button>
                                </div>
                            )}
                            {!isMobile && (
                                <div
                                    style={{
                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                        width: 40,
                                        height: 40,
                                    }}
                                    className="d-flex justify-content-center align-items-center bluredIconButton mini m-0"
                                >
                                    <button
                                        style={{
                                            textDecoration: "none",
                                            color: "#fff",
                                            background: "transparent",
                                            border: "none",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModalQRCode(true);
                                        }}
                                    >
                                        <HiMiniQrCode size={23} />
                                    </button>
                                </div>
                            )}

                            <div
                                style={{
                                    background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                    width: 40,
                                    height: 40,
                                }}
                                className="d-flex justify-content-center align-items-center bluredIconButton mini m-0"
                            >
                                <button
                                    style={{
                                        textDecoration: "none",
                                        color: "#fff",
                                        background: "transparent",
                                        border: "none",
                                    }}
                                    onClick={(e) => handleShare()}
                                >
                                    <ExportIcon style={{ width: 18 }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
});

export default AudioCard;
